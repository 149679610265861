html,
body,
#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
}

.year {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px solid #ddd;
  background: #fff;
}

.month {
  /* margin: 5px 5px 15px 5px; */
  /* width: 40%; */
  margin: 15px 10px;
}

.month-name {
  /* color: #ccbe88;
  font-weight: bold; */
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  text-align: center;
  align-items: center;
  color: #012169;
  margin: 8px 10px;
}

.day {
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 30px;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 3px 6px;
}

.date {
  width: 24px;
  height: 24px;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  background: transparent;
  text-align: center;
  padding: 0px;
  border-radius: 50px;
  border: none;
  outline: none;
  margin: 0px 6px;
  color: #6e6e6e;
}

.date.in-month:hover {
  cursor: pointer;
  /* background: #E2F3FF; */
}

.curday {
  background: #e2f3ff;
  color: #6e6e6e;
}

.today {
  font-weight: bold;
  color: #012169;
}

.date.next-or-prev-month {
  color: #dedede;
}
